
header {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (max-width: 750px) {
  header {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
