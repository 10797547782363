/* Base Application Styles */

@use '../styles/globals';

body {
  line-height: 1.6;
  color: #000;
  font-family: globals.$font-body;
  font-size: globals.$font-size-body;
}

a {
  text-decoration: none;
  color: globals.$theme-color;

  &:hover {
    color: globals.$theme-color-hover;
    text-decoration: underline;
  }
}

.separator {
  border-top: 1px solid #eee;
}

.hidden {
  display: none;
}

button {
  background-color: #00b5e2;
  color: #fff;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

input[type='radio'] {
  margin: 0.5rem;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 16px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #00b5e2;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 16px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #00b5e2;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #00b5e2;
}
