

footer {
  margin-top: 20px !important;
  margin-bottom: 20px !important;

  img {
    max-width: 100%;
  }

  .footer-row {
    display: flex;
  }

  .footer-col-img {
    display: flex;
    align-items: center;
    justify-content: center;
    float: none;
  }

  .footer-col-txt {
    display: flex;
    align-items: center;
    float: none;
    flex-direction: column;
    text-align: center;
  }

}

